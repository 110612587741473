// store/auth.js

// // reusable aliases for mutations
export const AUTH_MUTATIONS = {
    SET_USER: "SET_USER",
    SET_PAYLOAD: "SET_PAYLOAD",
    LOGOUT: "LOGOUT",
};

export const state = () => ({
    // access_token: null, // JWT access token
    refToken: null, // JWT refresh token
    id: null, // user id
    email: null, // user email address
    firstName: null,
    lastName: null,
    accessLevel: null,
});

export const mutations = {
    //     // store the logged in user in the state
    [AUTH_MUTATIONS.SET_USER](
        state,
        { id, email, token, firstName, lastName, accessLevel }
    ) {
        state.id = id;
        state.email = email;
        state.refToken = token;
        state.firstName = firstName;
        state.lastName = lastName;
        state.accessLevel = accessLevel;
    },

    // store new or updated token fields in the state
    [AUTH_MUTATIONS.SET_PAYLOAD](
        state,
        { id, email, refToken = null, firstName, lastName, accessLevel }
    ) {
        // state.access_token = access_token;
        if (id) {
            state.id = id;
        }
        if (email) {
            state.email = email;
        }
        // refresh token is optional, only set it if present
        if (refToken) {
            state.refToken = refToken;
        }

        // if the user is logged in, set the Authorization header with the new token
        if (state.access_token) {
            this.$axios.setToken(state.access_token, "Bearer");
        }

        // if the user is logged in, set the Authorization header with the new token
        if (state.refToken) {
            this.$axios.setToken(state.refToken, "Bearer");
        }

        if (state.firstName) {
            state.firstName = firstName;
        }

        if (state.lastName) {
            state.lastName = lastName;
        }
        if (state.accessLevel) {
            state.accessLevel = accessLevel;
        }
    },

    // clear our the state, essentially logging out the user
    [AUTH_MUTATIONS.LOGOUT](state) {
        state.id = null;
        state.email = null;
        state.firstName = null;
        state.lastName = null;
        state.accessLevel = null;
        // state.access_token = null;
        if (state.refToken) {
            state.refToken = null;
        }
    },
};

const AUTH_USER = `
    mutation authenticate($email: String!, $pass: String!) {
        authenticateMembroWithPassword(email: $email, password: $pass) {
          token
          item {
            email
            id
            firstName
            lastName
            accessLevel
          }
        }
      }
`;
const DEAUTH_USER = `
    mutation unauthenticate {
        unauthenticateMembro {
          success
        }
      }
`;
const CHECK_USER = `
    query check {
        authenticatedMembro {
            id  
            email
            firstName
            lastName
            accessLevel
        }
      }
`;
const graphql = async (query, variables) => {
    const res = await fetch("/admin/api", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            query,
            variables,
        }),
    });
    return await res.json();
};

export const actions = {
    async login({ commit, dispatch }, { email_address, password }) {
        // make an API call to login the user with an email address and password
        try {
            const { data } = await graphql(AUTH_USER, {
                email: email_address,
                pass: password,
            });

            if (data && data.authenticateMembroWithPassword) {
                commit(AUTH_MUTATIONS.SET_USER, {
                    id: data.authenticateMembroWithPassword.item.id,
                    email: data.authenticateMembroWithPassword.item.email,
                    token: data.authenticateMembroWithPassword.token,
                    firstName:
                        data.authenticateMembroWithPassword.item.firstName,
                    lastName: data.authenticateMembroWithPassword.item.lastName,
                    accessLevel:
                        data.authenticateMembroWithPassword.item.accessLevel,
                });

                // // refresh the access token
            } else {
                commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                    id: null,
                    email: null,
                    refToken: null,
                    firstName: null,
                    lastName: null,
                    accessLevel: null,
                });
                // catch any errors and automatically logout the user
                await dispatch("auth/logout");
            }
        } catch (e) {
            console.log(e);
        }
        // // commit the user and tokens to the state
        // commit(AUTH_MUTATIONS.SET_PAYLOAD, payload);
    },

    // async register({ commit }, { email_addr, password }) {
    //     // make an API call to register the user
    //     const {
    //         data: {
    //             data: { user, payload },
    //         },
    //     } = await this.$axios.post("/v2api/auth/register", {
    //         email_address,
    //         password,
    //     });
    // },

    // given the current refresh token, refresh the user's access token to prevent expiry
    async refresh({ commit, state }) {
        const { refToken } = state;
        const { data } = await graphql(CHECK_USER, {
            token: refToken,
        });

        if (data && data.authenticatedMembro && data.authenticatedMembro.id) {
            // console.log("AUTHED", data);

            commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                id: data.authenticatedMembro.id,
                email: data.authenticatedMembro.email,
                refToken: refToken,
                firstName: data.authenticatedMembro.firstName,
                lastName: data.authenticatedMembro.lastName,
                accessLevel: data.authenticatedMembro.accessLevel,
            });
        } else {
            commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                id: null,
                email: null,
                refToken: null,
                firstName: null,
                lastName: null,
                accessLevel: null,
            });
            // catch any errors and automatically logout the user
            commit(AUTH_MUTATIONS.LOGOUT);
        }
    },

    // logout the user
    async logout({ commit, state }) {
        const { success } = await graphql(DEAUTH_USER, {
            token: state.refToken,
        });

        if (success) {
            commit(AUTH_MUTATIONS.SET_PAYLOAD, {
                id: null,
                email: null,
                refToken: null,
                firstName: null,
                lastName: null,
                accessLevel: null,
            });
            commit(AUTH_MUTATIONS.LOGOUT);
        }
    },
};

export const getters = {
    // determine if the user is authenticated based on the presence of the access token
    isAuthenticated: (state) => {
        return (
            state.id &&
            state.id !== "" &&
            state.email &&
            state.email !== "" &&
            state.refToken &&
            state.refToken !== "" &&
            state.firstName &&
            state.firstName !== "" &&
            state.lastName &&
            state.lastName !== "" &&
            state.accessLevel &&
            state.accessLevel !== ""
        );
    },
    authedUser: (state) => {
        return {
            id: state.id,
            email: state.email,
            refToken: state.refToken,
            firstName: state.firstName,
            lastName: state.lastName,
            accessLevel: state.accessLevel,
        };
    },

    token: (state) => {
        return state.refToken;
    },
};
