<template>
    <div class="nav-container is-family-secondary glassy-bg">
        <div class="menu-backdrop-blur glassy-bg" />
        <b-navbar
            :close-on-click="false"
            fixed-top
            :active.sync="isActive"
            class="is-transparent is-vcentered container has-text-link"
        >
            <template slot="brand">
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    class="brand-logo"
                    tag="router-link"
                    :to="localePath('/')"
                >
                    <Logo />
                    <span
                        class="desktop-logo is-7 has-text-weight-bold is-uppercase"
                        :class="
                            currentPage == '' ? 'has-text-link' : 'is-subtle'
                        "
                        >Tempio Del Futuro *Perduto</span
                    >
                    <!-- <span
            class="mobile-menu-logo mt-1 is-7 has-text-weight-bold is-uppercase"
            :class="currentPage == 'newhome' ? 'has-text-link' : 'is-subtle'"
            >Tempio Del Futuro *Perduto</span
          > -->
                </b-navbar-item>
            </template>
            <template slot="burger">
                <div class="burger-container">
                    <b-button
                        as="a"
                        :class="{ 'is-active': isActive }"
                        @click="!isActive ? animateMenuIn() : animateMenuOut()"
                        class="burger-btn is-rounded is-subtle is-family-secondary"
                    >
                        <b-icon
                            icon-pack="fas"
                            class="burger-icon is-subtle"
                            :icon="isActive ? 'fas fa-times' : 'fas fa-bars'"
                        />
                    </b-button>
                </div>
            </template>
            <template slot="end">
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    tag="router-link"
                    class="menu-item"
                    :class="currentPage == 'events' ? 'is-active' : ''"
                    :to="localePath('events')"
                >
                    {{ $t("events.header") }}
                </b-navbar-item>
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    tag="router-link"
                    class="menu-item"
                    :class="currentPage == 'radio' ? 'is-active' : ''"
                    :to="localePath('radio')"
                >
                    <!-- :to="{ path: '/radio' }" -->
                    {{ $t("radio.header") }}
                </b-navbar-item>
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    tag="router-link"
                    class="menu-item"
                    :class="currentPage == 'virtuale' ? 'is-active' : ''"
                    :to="localePath('virtuale')"
                >
                    {{ $t("virtual.header") }}
                </b-navbar-item>
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    tag="router-link"
                    class="menu-item"
                    :class="currentPage == 'contribute' ? 'is-active' : ''"
                    :to="localePath('contribute')"
                >
                    {{ $t("contribute.header") }}
                </b-navbar-item>
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    tag="a"
                    class="menu-item"
                    href="https://artsandculture.google.com/partner/tempio-del-futuro-perduto"
                    target="_blank"
                >
                    {{ $t("exhibit.header") }}
                </b-navbar-item>
                <b-navbar-item
                    @click.native="isActive ? animateMenuOut() : ''"
                    tag="router-link"
                    class="menu-item"
                    :class="currentPage == 'where' ? 'is-active' : ''"
                    :to="localePath('where')"
                >
                    {{ $t("where.header") }}
                </b-navbar-item>
            </template>
            <!-- <template slot="menu"> glab </template> -->
        </b-navbar>
    </div>
</template>
<style lang="scss" scoped>
.menu-backdrop-blur {
    z-index: 0;
    position: absolute;
    top: 5rem;
    left: 0;
    height: calc(100vh - 5rem);
    width: 100vw;
    pointer-events: none;
}
.nav-container {
    // display: block;
    // background: var(--bg);
    // background: linear-gradient(
    //   0deg,
    //   rgba(var(--bg-rgba), 0.9) 0%,
    //   rgba(var(--bg-rgba), 0.99) 60%,
    //   rgba(var(--bg-rgba), 1) 100%
    // );
    width: 100vw;
    // background: white;
    position: fixed;
    top: 0;
    left: 0;
    // left: 3px;
    height: 5rem;
    // margin-bottom: 140px;
    // margin-bottom: 300px; //12vh in anim
    z-index: 10;
    transition: 220ms;
    transition-timing-function: ease-in-out;
    // @media (min-width: 768px)  {
    // box-shadow: 0px 0.3px 1px -31px rgba(78, 67, 67, 0.107),
    //   0px 0.7px 2.4px -31px rgba(78, 67, 67, 0.153),
    //   0px 1.3px 4.5px -31px rgba(78, 67, 67, 0.19),
    //   0px 2.2px 8px -31px rgba(78, 67, 67, 0.227),
    //   0px 4.2px 15px -31px rgba(78, 67, 67, 0.273),
    //   0px 10px 36px -31px rgba(78, 67, 67, 0.38)
    // // }
    // @media (max-width: 768px) {
    //   box-shadow: 0px 0.6px 1px -31px rgba(78, 67, 67, 0.18),
    //     0px 1.3px 2.4px -31px rgba(78, 67, 67, 0.258),
    //     0px 2.5px 4.5px -31px rgba(78, 67, 67, 0.32),
    //     0px 4.5px 8px -31px rgba(78, 67, 67, 0.382),
    //     0px 8.4px 15px -31px rgba(78, 67, 67, 0.46),
    //     0px 20px 36px -31px rgba(78, 67, 67, 0.64);
    // }
}
.dark-mode .nav-container {
    // @media (min-width: 768px)  {
    // box-shadow: 0px 0.3px 1px -31px rgba(0, 0, 0, 0.18),
    //   0px 0.7px 2.4px -31px rgba(0, 0, 0, 0.258),
    //   0px 1.3px 4.5px -31px rgba(0, 0, 0, 0.32),
    //   0px 2.2px 8px -31px rgba(0, 0, 0, 0.382),
    //   0px 4.2px 15px -31px rgba(0, 0, 0, 0.46),
    //   0px 10px 36px -31px rgba(0, 0, 0, 0.64);
}
.navbar {
    // padding: 0 2.5vw;
    // overflow: hidden;
    // height: 12vh;
    height: 5rem;
    // z-index: 9999;
    width: 100%;
    top: 0;
    .brand-logo {
        // transition-delay: 0;
        // transition: 220ms;
        height: 5rem;
        // margin: 0 0.5em;
        width: auto;
        // transition-timing-function: ease-in-out;
        // padding: 5vh 0;
        // height: 72px;
        // font-family: "Istok Web", sans-serif;
        text-transform: capitalize;
        @media (min-width: 960px) {
            padding: 0;
        }
    }
    .menu-item {
        text-transform: uppercase;
        font-weight: bold;
        @media (max-width: 960px) {
            font-size: 1.25em;
            background: none;
        }
    }
}
.burger-container {
    position: relative;
    height: 5rem;
    width: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 0.5em;
    text-align: center;
    // @media (max-width: 960px) {
    //   display: block;
    // }
    @media (min-width: 960px) {
        display: none;
    }
}
.burger-icon {
    background: none !important;
    background-color: none !important;
}
.brand-icon {
    height: 5rem;
    width: 3.5rem;
    object-fit: contain;

    // padding: 0 0.35rem;
    margin-right: 0.3em;
    @media (max-width: 960px) {
        // display: none;
    }
}
// .mobile-cart-btn,
// .mobile-acct-btn {
//   @media (min-width: 768px) {
//     display: none;
//   }
// }
// .desktop-cart-btn,
// .desktop-acct-btn {
//   @media (max-width: 768px) {
//     display: none;
//   }
// }
// .menu-item {
//   width: 1005;
//   margin: 0 3rem;
// }
.navbar-item img {
    max-height: 3rem;
    margin-left: 0;
    // padding: 2rem 0;
}
.v-icon {
    // display: block;
    // width: 100%;
    // height: 72px;
    // width: 3em;
    // position: relative;
    // display: block;
    // width: 5rem;
    // height: 5rem;
    // height: 48px;
    // width: 48px;
    margin: 1rem 0.25rem 0 0.25rem;
    width: 100%;
    // padding: 2rem 0;
    height: 100%;
    // padding: 0 ;
}
.glassy-bg {
    background: rgba(var(--bg-rgba), 0.7);
    backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
    // background: rgba(var(--bg-rgba), 0.9);
    // backdrop-filter: blur(1rem);
}
</style>
<script>
import Logo from "~/components/Logo";

export default {
    name: "AppNavbar",
    // transition: "fade",
    components: { Logo },
    data() {
        return {
            isActive: false,
        };
    },
    props: {
        isSolidNavbar: { type: Boolean, default: true, required: true },
        currentPage: { type: String, default: "", required: true },
    },
    mounted() {
        this.navReveal();
        // console.log(this.currentPage);
    },
    watch: {
        isActive: function (newVal, oldVal) {
            console.log(oldVal);
            console.log(newVal);
            if (newVal === true) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        animateMenuIn() {
            const anime = this.$anime;
            anime({
                targets: ".burger-icon",
                // translateY: [-20, 0],
                // height: ["40vh", "50vh"],
                opacity: [1, 0, 1],
                easing: "easeInOutCirc",
                // delay: 100,
                duration: 350,
            });
            // anime({
            //   targets: ".mobile-menu-logo",
            //   // translateY: [-20, 0],
            //   // height: ["40vh", "50vh"],
            //   opacity: [0, 1],
            //   translateX: [-5, 0],
            //   easing: "easeInOutCirc",
            //   delay: 200,
            //   duration: 350,
            // });

            setTimeout(() => {
                this.isActive = true;
            }, 250);
            anime({
                targets: ".navbar-menu",
                translateY: [-20, 0],
                height: ["40vh", "60vh"],

                opacity: [0, 1],
                easing: "easeInOutCirc",
                delay: 100,
                duration: 350,
            });
            anime({
                targets: ".menu-backdrop-blur",
                opacity: [0, 1],
                easing: "easeInOutCirc",
                delay: 100,
                duration: 350,
            });
        },
        animateMenuOut() {
            const anime = this.$anime;
            anime({
                targets: ".menu-backdrop-blur",
                opacity: [1, 0],
                easing: "easeInOutCirc",
                delay: 100,
                duration: 350,
            });
            anime({
                targets: ".burger-icon",
                // translateY: [-20, 0],
                // height: ["40vh", "50vh"],
                opacity: [1, 0, 1],
                easing: "easeInOutCirc",
                // delay: 100,
                duration: 350,
            });
            anime({
                targets: ".navbar-menu",
                translateY: [0, -20],
                height: ["60vh", "40vh"],
                opacity: [1, 0],
                easing: "easeInOutCirc",
                duration: 250,
            });
            setTimeout(() => {
                this.isActive = false;
            }, 250);
        },
        // console.log(this.isActive);

        navReveal() {
            const anime = this.$anime;

            anime({
                targets: ".brand-icon",
                // translateY: [20, 0],
                opacity: [0, 1],
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: 120,
                duration: 250,
            });
            anime({
                translateY: 100,
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: 250,
                duration: 250,
            });
            anime({
                targets: ".desktop-logo",
                // translateY: [20, 0],
                opacity: [0, 1],
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: 325,
                duration: 250,
            });
            anime({
                targets: ".menu-item",
                // translateY: [20, 0],
                opacity: [0, 1],
                easing: "easeInOutCirc",
                // backgroundColor: '#FFF',
                delay: anime.stagger(100, { start: 450 }),
                duration: 250,
            });
        },
    },
};
</script>
