<template>
  <div class="nav-container is-family-secondary glassy-bg">
    <div class="menu-backdrop-blur glassy-bg" />
    <b-navbar
      :close-on-click="false"
      fixed-top
      :active.sync="isActive"
      class="is-transparent is-vcentered container has-text-link"
    >
      <template slot="brand">
        <b-navbar-item
          @click.native="isActive ? animateMenuOut() : ''"
          class="brand-logo"
          tag="router-link"
          :to="localePath('/internal')"
        >
          <b-button
            as="a"
            :class="{ 'is-active': isActive }"
            @click="goBack()"
            v-if="isIosPwaBackButton && canPressBack"
            class="back-btn is-rounded is-subtle is-family-secondary"
          >
            <b-icon
              icon-pack="fas"
              class="burger-icon is-subtle"
              icon="fas fa-arrow-left"
            />
          </b-button>
          <Logo />
          <span
            v-if="mobile === false"
            class="desktop-logo is-7 has-text-weight-bold is-uppercase"
            :class="currentPage == '' ? 'has-text-link' : 'is-subtle'"
            >Tempio Del Futuro *Perduto</span
          >

          <span
            v-else
            class="mt-1 is-7 has-text-weight-bold is-uppercase"
            :class="currentPage == '' ? 'has-text-link' : 'is-subtle'"
            >{{
              isActive ? "TDF*P" : this.$store.getters["getCurrPageTitle"]
            }}</span
          >
        </b-navbar-item>
      </template>
      <template slot="burger" v-if="mobile === true">
        <div class="burger-container">
          <b-button
            as="a"
            v-if="this.$store.getters['auth/authedUser'].firstName"
            :class="{ 'is-active': isActive }"
            @click="!isActive ? animateMenuIn() : animateMenuOut()"
            class="burger-btn is-rounded is-subtle is-family-secondary"
          >
            <b-icon
              icon-pack="fas"
              class="burger-icon is-subtle"
              :icon="isActive ? 'fas fa-times' : 'fas fa-user'"
            />
          </b-button>
        </div>
      </template>
      <template slot="end">
        <div
          class="card grid-item-xl"
          v-if="
            isActive &&
            this.$store.getters['auth/authedUser'].firstName &&
            mobile === true
          "
        >
          <div class="card-content row-content">
            <div class="content">
              <div
                class="is-size-3 has-text-primary-alt has-text-weight-bold mb-2"
              >
                {{ this.$store.getters["auth/authedUser"].firstName }}
              </div>
            </div>
            <div class="content">
              <b-navbar-item
                @click.native="isActive ? animateMenuOut() : ''"
                tag="router-link"
                class="menu-item logged-menu-item"
                :class="currentPage == 'internal' ? 'is-active' : ''"
                :to="localePath('/internal')"
              >
                <b-icon
                  icon="fas fa-tachometer-alt"
                  size="is-small"
                  class="mx-1"
                />
                {{ $t("internalDashboard.header") }}
              </b-navbar-item>
              <b-navbar-item
                @click.native="authLogout()"
                tag="router-link"
                class="menu-item logged-menu-item logout-item"
                icon-right="fas fa-sign-out-alt"
                :class="currentPage == 'internal' ? 'is-active' : ''"
                :to="localePath('/internal')"
              >
                <b-icon
                  icon="fas fa-sign-out-alt"
                  size="is-small"
                  class="mx-1"
                />
                {{ $t("actions.logout") }}
              </b-navbar-item>
            </div>
          </div>
        </div>
        <div class="menu-body">
          <div class="menu-scroller">
            <span class="category-item" v-if="this.mobile">
              {{ $t("categories.internal.scanner.header") }}
            </span>
            <b-navbar-item
              v-if="this.$store.getters['auth/authedUser'].firstName"
              @click.native="isActive ? animateMenuOut() : ''"
              tag="router-link"
              class="menu-item main"
              :class="currentPage == '/internal/validate' ? 'is-active' : ''"
              :to="localePath('/internal/validate')"
            >
              {{ $t("categories.internal.memberships.header") }}
            </b-navbar-item>

            <b-navbar-item
              v-if="
                this.$store.getters['auth/authedUser'].firstName &&
                this.$store.getters['auth/authedUser'].accessLevel > 0
              "
              @click.native="isActive ? animateMenuOut() : ''"
              tag="router-link"
              class="menu-item main"
              :class="currentPage == '/internal/ticketcheck' ? 'is-active' : ''"
              :to="localePath('/internal/ticketcheck')"
            >
              {{ $t("categories.internal.tickets.header") }}
            </b-navbar-item>
            <span
              v-if="
                this.mobile &&
                this.$store.getters['auth/authedUser'].firstName &&
                this.$store.getters['auth/authedUser'].accessLevel > 0
              "
              class="category-item"
            >
              {{ $t("categories.internal.manage.header") }}
            </span>
            <b-navbar-item
              v-if="
                this.$store.getters['auth/authedUser'].firstName &&
                this.$store.getters['auth/authedUser'].accessLevel > 0
              "
              @click.native="isActive ? animateMenuOut() : ''"
              tag="router-link"
              class="menu-item main"
              :class="currentPage == '/internal/home' ? 'is-active' : ''"
              :to="localePath('/internal/home')"
            >
              {{ $t("categories.internal.home.header") }}
            </b-navbar-item>
            <b-navbar-item
              v-if="
                this.$store.getters['auth/authedUser'].firstName &&
                this.$store.getters['auth/authedUser'].accessLevel > 0
              "
              @click.native="isActive ? animateMenuOut() : ''"
              tag="router-link"
              class="menu-item main"
              :class="currentPage == '/internal/news' ? 'is-active' : ''"
              :to="localePath('/internal/news')"
            >
              {{ $t("categories.internal.news.header") }}
            </b-navbar-item>
            <b-navbar-item
              v-if="
                this.$store.getters['auth/authedUser'].firstName &&
                this.$store.getters['auth/authedUser'].accessLevel > 0
              "
              @click.native="isActive ? animateMenuOut() : ''"
              tag="router-link"
              class="menu-item main"
              :class="currentPage == '/internal/events' ? 'is-active' : ''"
              :to="localePath('/internal/events')"
            >
              {{ $t("categories.internal.events.header") }}
            </b-navbar-item>
            <b-navbar-item
              v-if="this.$store.getters['auth/authedUser'].firstName"
              @click.native="isActive ? animateMenuOut() : ''"
              tag="router-link"
              class="menu-item main"
              :class="currentPage == '/internal/list' ? 'is-active' : ''"
              :to="localePath('/internal/list')"
            >
              {{ $t("categories.internal.members.header") }}
            </b-navbar-item>
            <b-navbar-item
              v-if="this.$store.getters['auth/authedUser'].firstName && !mobile"
              @click.native="isActive ? animateMenuOut() : ''"
              tag="router-link"
              class="menu-item main"
              :class="currentPage == '/internal' ? 'is-active' : ''"
              :to="localePath('/internal')"
            >
              {{ $t("categories.internal.dashboard.header") }}
            </b-navbar-item>
          </div>
          <AppFooter v-if="mobile" :onClickOut="animateMenuOut" />
        </div>
      </template>
    </b-navbar>
  </div>
</template>
<style lang="scss" scoped>
.back-btn {
  min-height: 5rem;
  height: 5rem;
  min-width: 3.5rem;
  width: 3.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.logged-menu-item {
  margin: 0.5rem 0;
  padding: 0;
}
.logout-item {
  color: var(--color-danger) !important;
  * {
    color: var(--color-danger) !important;
  }
}
.card {
  background: var(--bg-secondary);
  border-radius: 1rem;
  height: auto;
  // width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: initial;
  margin: 0 1.5rem 1rem 1.5rem;
}
.spacer {
  @media (min-width: 767px) {
    width: 0;
  }
  @media (max-width: 767px) {
    width: 30vw;
    background: var(--color-primary);
    height: 1px;
    display: block;
    min-width: 30vw;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
  }
}

.menu-backdrop-blur {
  z-index: 0;
  pointer-events: none;
  position: absolute;
  top: 5em;
  height: calc(100vh);
  width: 100vw;
  overflow: hidden;
  z-index: 1;
  opacity: 0;
}
.nav-container {
  width: 100vw;

  position: fixed;
  top: 0;
  left: 0;
  // left: 3px;
  height: 5rem;

  z-index: 10;
  transition: 220ms;
  transition-timing-function: ease-in-out;
}
.navbar {
  height: 5rem;

  width: 100%;
  top: 0;
  .brand-logo {
    height: 5rem;
    width: auto;

    text-transform: capitalize;
    @media (min-width: 767px) {
      padding: 0;
    }
  }
  .is-primary-menu-item {
    color: var(--color-primary-alt) !important;
  }
  .category-item {
    text-transform: uppercase;
    font-weight: bold;
    color: rgba(var(--color-subtle-rgba), 0.5);
    pointer-events: none;
    @media (max-width: 767px) {
      font-size: 1.25em;
      margin: 0rem 2rem 0.5rem 2rem;
      background: none;
      // max-width: 80vw;
    }
  }
  .menu-item {
    text-transform: uppercase;
    font-weight: bold;
    @media (max-width: 767px) {
      font-size: 1.25em;

      background: none;
      &.main {
        margin: 0.5rem 2rem;
      }
    }
  }
}
.burger-container {
  position: relative;
  height: 5rem;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 0.5em;
  text-align: center;
  // @media (max-width: 767px) {
  //   display: block;
  // }
}
.burger-icon {
  background: none !important;
  background-color: none !important;
}
.brand-icon {
  height: 5rem;
  width: 3.5rem;
  object-fit: contain;

  // padding: 0 0.35rem;
  margin-right: 0.3em;
  @media (max-width: 767px) {
    // display: none;
  }
}
.navbar-item img {
  max-height: 3rem;
  margin-left: 0;
}
.v-icon {
  margin: 1rem 0.25rem 0 0.25rem;
  width: 100%;

  height: 100%;
}
.glassy-bg {
  /* background: rgba(var(--bg-secondary-rgba), 0.8); */
  background: linear-gradient(
    180deg,
    rgba(var(--bg-secondary-rgba), 1) 0%,
    rgba(var(--bg-secondary-rgba), 0.8) 100%
  );
  backdrop-filter: blur(2rem);
  -webkit-backdrop-filter: blur(2rem);
}
.glassy-bg-flat {
  z-index: 0;
  background: rgba(var(--bg-secondary-rgba), 0.8);
  backdrop-filter: blur(2rem) !important;
  -webkit-backdrop-filter: blur(2rem) !important;
}
</style>
<script>
import Logo from "~/components/Logo";
import AppFooter from "~/components/AppFooter";
import * as Cookies from "js-cookie";

export default {
  name: "BackNavbar",
  // transition: "fade",
  components: { Logo, AppFooter },
  data() {
    return {
      canPressBack: false,
      isIosPwaBackButton: false,
      isActive: false,
      mobile: false,
      loggedIn: this.$store.getters["getInternalLoggedInStatus"],
    };
  },
  props: {
    isSolidNavbar: { type: Boolean, default: true, required: true },
    currentPage: { type: String, default: "", required: true },
  },
  mounted() {
    try {
      if (process.client && navigator) {
        // this.$store.dispatch("setInternalLoggedIn", this.loggedIn);

        const backButtonVisible =
          [
            "iPad Simulator",
            "iPhone Simulator",
            "iPod Simulator",
            "iPad",
            "iPhone",
            "iPod",
          ].includes(navigator.platform) ||
          (navigator.userAgent.includes("Mac") && "ontouchend" in document);
        this.canPressBack = this.canGoBack();
        // console.log("can go back: " + this.canPressBack);
        this.isIosPwaBackButton = backButtonVisible;
        this.navReveal();
        this.$nextTick(function () {
          this.mobile = this.$store.getters["getIsMobile"];
        });

        window.addEventListener("resize", this.handleResize);
      }
    } catch (e) {
      console.log(e);
    }
  },
  watch: {
    isActive: function (newVal, oldVal) {
      // console.log(oldVal);
      // console.log(newVal);
      if (newVal === true) {
        return true;
      } else {
        return false;
      }
    },
    $route(to, from) {
      this.canPressBack = this.canGoBack();
      try {
        if (navigator) {
          const backButtonVisible =
            [
              "iPad Simulator",
              "iPhone Simulator",
              "iPod Simulator",
              "iPad",
              "iPhone",
              "iPod",
            ].includes(navigator.platform) ||
            (navigator.userAgent.includes("Mac") && "ontouchend" in document);
          this.canPressBack = this.canGoBack();
          this.isIosPwaBackButton = backButtonVisible;
        }
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    isSolid() {
      return this.isSolidNavbar;
    },
    isCurrentPage() {
      return this.currentPage;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    canGoBack() {
      let result = false;
      // check if the current route has a parent
      if (this.$router.history.current.name.startsWith("internal-login")) {
        result = false;
      } else if (this.$router.history.current.name.startsWith("internal-")) {
        result = true;
      }
      return result;
    },
    handleResize() {
      this.mobile = this.$store.getters["getIsMobile"];
    },
    async authLogout() {
      this.animateMenuOut();
      try {
        console.log("browser base url: " + process.env.BROWSER_BASE_URL);
        const isDev = process.env.NODE_ENV !== "production";

        await this.$store.dispatch("auth/logout");
        await this.$store.dispatch("auth/refresh");

        // console.log("logout: " + logout);
        if (this.$cookies.get("authrefToken").length > 0) {
          Cookies.remove("authrefToken", {
            path: "/",
            domain: isDev ? "localhost" : process.env.BROWSER_BASE_URL,
          });
        }
        // refresh the page

        this.$router.push(this.localePath("/internal/login"));
        this.$store.dispatch("setInternalLoggedIn", false);

        // this.$router.go();
      } catch (e) {
        console.error(e);
      } finally {
      }
    },

    animateMenuIn() {
      const anime = this.$anime;
      anime({
        targets: ".burger-icon",
        // translateY: [-20, 0],
        // height: ["40vh", "50vh"],
        opacity: [1, 0, 1],
        easing: "easeInOutCirc",
        // delay: 100,
        duration: 350,
      });

      setTimeout(() => {
        this.isActive = true;
      }, 250);
      anime({
        targets: ".navbar-menu",
        translateY: [-20, 0],
        height: ["80vh", "100vh"],

        opacity: [0, 1],
        easing: "easeInOutCirc",
        delay: 100,
        duration: 350,
      });
      anime({
        targets: ".menu-backdrop-blur",
        opacity: [0, 1],
        easing: "easeInOutCirc",
        delay: 100,
        duration: 350,
      });
    },
    animateMenuOut() {
      const anime = this.$anime;
      anime({
        targets: ".menu-backdrop-blur",
        opacity: [1, 0],
        easing: "easeInOutCirc",
        delay: 100,
        duration: 350,
      });
      anime({
        targets: ".burger-icon",
        // translateY: [-20, 0],
        // height: ["40vh", "50vh"],
        opacity: [1, 0, 1],
        easing: "easeInOutCirc",
        // delay: 100,
        duration: 350,
      });
      anime({
        targets: ".navbar-menu",
        translateY: [0, -20],
        height: ["100vh", "80vh"],
        opacity: [1, 0],
        easing: "easeInOutCirc",
        duration: 250,
      });
      setTimeout(() => {
        this.isActive = false;
      }, 250);
    },

    navReveal() {
      const anime = this.$anime;

      anime({
        targets: ".brand-icon",
        // translateY: [20, 0],
        opacity: [0, 1],
        easing: "easeInOutCirc",
        // backgroundColor: '#FFF',
        delay: 120,
        duration: 250,
      });
      anime({
        translateY: 100,
        easing: "easeInOutCirc",
        // backgroundColor: '#FFF',
        delay: 250,
        duration: 250,
      });
      anime({
        targets: ".desktop-logo",
        // translateY: [20, 0],
        opacity: [0, 1],
        easing: "easeInOutCirc",
        // backgroundColor: '#FFF',
        delay: 325,
        duration: 250,
      });
      anime({
        targets: ".menu-item",
        // translateY: [20, 0],
        opacity: [0, 1],
        easing: "easeInOutCirc",
        // backgroundColor: '#FFF',
        delay: anime.stagger(100, { start: 450 }),
        duration: 250,
      });
    },
  },
};
</script>
