import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _70d3bbec = () => interopDefault(import('../src/pages/contribute.vue' /* webpackChunkName: "pages/contribute" */))
const _354d64c0 = () => interopDefault(import('../src/pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _40edd526 = () => interopDefault(import('../src/pages/cookies-en.vue' /* webpackChunkName: "pages/cookies-en" */))
const _201004a3 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _188077d0 = () => interopDefault(import('../src/pages/events.vue' /* webpackChunkName: "pages/events" */))
const _7bd6b37f = () => interopDefault(import('../src/pages/internal/index.vue' /* webpackChunkName: "pages/internal/index" */))
const _0948a682 = () => interopDefault(import('../src/pages/marketold/index.vue' /* webpackChunkName: "pages/marketold/index" */))
const _b6c3ebce = () => interopDefault(import('../src/pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _60d69a0d = () => interopDefault(import('../src/pages/privacy-en.vue' /* webpackChunkName: "pages/privacy-en" */))
const _19e0f5e8 = () => interopDefault(import('../src/pages/refunds.vue' /* webpackChunkName: "pages/refunds" */))
const _2ead538c = () => interopDefault(import('../src/pages/refunds-en.vue' /* webpackChunkName: "pages/refunds-en" */))
const _6be47a50 = () => interopDefault(import('../src/pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _35fa9624 = () => interopDefault(import('../src/pages/terms-en.vue' /* webpackChunkName: "pages/terms-en" */))
const _f44ff798 = () => interopDefault(import('../src/pages/thankyou.vue' /* webpackChunkName: "pages/thankyou" */))
const _00e1d149 = () => interopDefault(import('../src/pages/u/index.vue' /* webpackChunkName: "pages/u/index" */))
const _205d610e = () => interopDefault(import('../src/pages/virtuale.vue' /* webpackChunkName: "pages/virtuale" */))
const _16908638 = () => interopDefault(import('../src/pages/where.vue' /* webpackChunkName: "pages/where" */))
const _10cef0bc = () => interopDefault(import('../src/pages/internal/events.vue' /* webpackChunkName: "pages/internal/events" */))
const _4b999842 = () => interopDefault(import('../src/pages/internal/home.vue' /* webpackChunkName: "pages/internal/home" */))
const _8e6d46fe = () => interopDefault(import('../src/pages/internal/list.vue' /* webpackChunkName: "pages/internal/list" */))
const _457e5696 = () => interopDefault(import('../src/pages/internal/login.vue' /* webpackChunkName: "pages/internal/login" */))
const _5001990e = () => interopDefault(import('../src/pages/internal/membership.vue' /* webpackChunkName: "pages/internal/membership" */))
const _4ab61a39 = () => interopDefault(import('../src/pages/internal/news/index.vue' /* webpackChunkName: "pages/internal/news/index" */))
const _5384792e = () => interopDefault(import('../src/pages/internal/ticketcheck.vue' /* webpackChunkName: "pages/internal/ticketcheck" */))
const _6dabab79 = () => interopDefault(import('../src/pages/internal/validate.vue' /* webpackChunkName: "pages/internal/validate" */))
const _a9048f3e = () => interopDefault(import('../src/pages/marketold/calendar.vue' /* webpackChunkName: "pages/marketold/calendar" */))
const _6b4e7560 = () => interopDefault(import('../src/pages/marketold/contribute.vue' /* webpackChunkName: "pages/marketold/contribute" */))
const _44aa3e3c = () => interopDefault(import('../src/pages/u/forgot-password.vue' /* webpackChunkName: "pages/u/forgot-password" */))
const _6aed1740 = () => interopDefault(import('../src/pages/u/login.vue' /* webpackChunkName: "pages/u/login" */))
const _f3c97b04 = () => interopDefault(import('../src/pages/u/manage.vue' /* webpackChunkName: "pages/u/manage" */))
const _d1ddb822 = () => interopDefault(import('../src/pages/u/membership.vue' /* webpackChunkName: "pages/u/membership" */))
const _300ec87c = () => interopDefault(import('../src/pages/u/register.vue' /* webpackChunkName: "pages/u/register" */))
const _1e24b8bb = () => interopDefault(import('../src/pages/u/confirm/activate.vue' /* webpackChunkName: "pages/u/confirm/activate" */))
const _88bf72e6 = () => interopDefault(import('../src/pages/u/confirm/expired.vue' /* webpackChunkName: "pages/u/confirm/expired" */))
const _758b406b = () => interopDefault(import('../src/pages/u/confirm/success.vue' /* webpackChunkName: "pages/u/confirm/success" */))
const _cee518c0 = () => interopDefault(import('../src/pages/u/confirm/trigger.vue' /* webpackChunkName: "pages/u/confirm/trigger" */))
const _59455c97 = () => interopDefault(import('../src/pages/u/recover/activate.vue' /* webpackChunkName: "pages/u/recover/activate" */))
const _522db731 = () => interopDefault(import('../src/pages/u/recover/expired.vue' /* webpackChunkName: "pages/u/recover/expired" */))
const _0c18b10f = () => interopDefault(import('../src/pages/u/recover/success.vue' /* webpackChunkName: "pages/u/recover/success" */))
const _72ec2b72 = () => interopDefault(import('../src/pages/internal/event/_id.vue' /* webpackChunkName: "pages/internal/event/_id" */))
const _6171523e = () => interopDefault(import('../src/pages/internal/news/_id.vue' /* webpackChunkName: "pages/internal/news/_id" */))
const _f761d69a = () => interopDefault(import('../src/pages/internal/view/_id.vue' /* webpackChunkName: "pages/internal/view/_id" */))
const _efe37478 = () => interopDefault(import('../src/pages/u/confirm/_confirmId.vue' /* webpackChunkName: "pages/u/confirm/_confirmId" */))
const _7d9562a0 = () => interopDefault(import('../src/pages/u/recover/_confirmId.vue' /* webpackChunkName: "pages/u/recover/_confirmId" */))
const _6bdf6ad4 = () => interopDefault(import('../src/pages/event/_id.vue' /* webpackChunkName: "pages/event/_id" */))
const _08d53edc = () => interopDefault(import('../src/pages/internal/_404.vue' /* webpackChunkName: "pages/internal/_404" */))
const _8d5e7986 = () => interopDefault(import('../src/pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _366a3fd2 = () => interopDefault(import('../src/pages/u/_404.vue' /* webpackChunkName: "pages/u/_404" */))
const _15498d90 = () => interopDefault(import('../src/pages/_404.vue' /* webpackChunkName: "pages/_404" */))
const _8e78108a = () => interopDefault(import('../src/pages/_oldindex.vue' /* webpackChunkName: "pages/_oldindex" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/contribute",
    component: _70d3bbec,
    name: "contribute___it"
  }, {
    path: "/cookies",
    component: _354d64c0,
    name: "cookies___it"
  }, {
    path: "/cookies-en",
    component: _40edd526,
    name: "cookies-en___it"
  }, {
    path: "/en",
    component: _201004a3,
    name: "index___en"
  }, {
    path: "/events",
    component: _188077d0,
    name: "events___it"
  }, {
    path: "/internal",
    component: _7bd6b37f,
    name: "internal___it"
  }, {
    path: "/marketold",
    component: _0948a682,
    name: "marketold___it"
  }, {
    path: "/privacy",
    component: _b6c3ebce,
    name: "privacy___it"
  }, {
    path: "/privacy-en",
    component: _60d69a0d,
    name: "privacy-en___it"
  }, {
    path: "/refunds",
    component: _19e0f5e8,
    name: "refunds___it"
  }, {
    path: "/refunds-en",
    component: _2ead538c,
    name: "refunds-en___it"
  }, {
    path: "/terms",
    component: _6be47a50,
    name: "terms___it"
  }, {
    path: "/terms-en",
    component: _35fa9624,
    name: "terms-en___it"
  }, {
    path: "/thankyou",
    component: _f44ff798,
    name: "thankyou___it"
  }, {
    path: "/u",
    component: _00e1d149,
    name: "u___it"
  }, {
    path: "/virtuale",
    component: _205d610e,
    name: "virtuale___it"
  }, {
    path: "/where",
    component: _16908638,
    name: "where___it"
  }, {
    path: "/en/contribute",
    component: _70d3bbec,
    name: "contribute___en"
  }, {
    path: "/en/cookies",
    component: _354d64c0,
    name: "cookies___en"
  }, {
    path: "/en/cookies-en",
    component: _40edd526,
    name: "cookies-en___en"
  }, {
    path: "/en/events",
    component: _188077d0,
    name: "events___en"
  }, {
    path: "/en/internal",
    component: _7bd6b37f,
    name: "internal___en"
  }, {
    path: "/en/marketold",
    component: _0948a682,
    name: "marketold___en"
  }, {
    path: "/en/privacy",
    component: _b6c3ebce,
    name: "privacy___en"
  }, {
    path: "/en/privacy-en",
    component: _60d69a0d,
    name: "privacy-en___en"
  }, {
    path: "/en/refunds",
    component: _19e0f5e8,
    name: "refunds___en"
  }, {
    path: "/en/refunds-en",
    component: _2ead538c,
    name: "refunds-en___en"
  }, {
    path: "/en/terms",
    component: _6be47a50,
    name: "terms___en"
  }, {
    path: "/en/terms-en",
    component: _35fa9624,
    name: "terms-en___en"
  }, {
    path: "/en/thankyou",
    component: _f44ff798,
    name: "thankyou___en"
  }, {
    path: "/en/u",
    component: _00e1d149,
    name: "u___en"
  }, {
    path: "/en/virtuale",
    component: _205d610e,
    name: "virtuale___en"
  }, {
    path: "/en/where",
    component: _16908638,
    name: "where___en"
  }, {
    path: "/internal/events",
    component: _10cef0bc,
    name: "internal-events___it"
  }, {
    path: "/internal/home",
    component: _4b999842,
    name: "internal-home___it"
  }, {
    path: "/internal/list",
    component: _8e6d46fe,
    name: "internal-list___it"
  }, {
    path: "/internal/login",
    component: _457e5696,
    name: "internal-login___it"
  }, {
    path: "/internal/membership",
    component: _5001990e,
    name: "internal-membership___it"
  }, {
    path: "/internal/news",
    component: _4ab61a39,
    name: "internal-news___it"
  }, {
    path: "/internal/ticketcheck",
    component: _5384792e,
    name: "internal-ticketcheck___it"
  }, {
    path: "/internal/validate",
    component: _6dabab79,
    name: "internal-validate___it"
  }, {
    path: "/marketold/calendar",
    component: _a9048f3e,
    name: "marketold-calendar___it"
  }, {
    path: "/marketold/contribute",
    component: _6b4e7560,
    name: "marketold-contribute___it"
  }, {
    path: "/u/forgot-password",
    component: _44aa3e3c,
    name: "u-forgot-password___it"
  }, {
    path: "/u/login",
    component: _6aed1740,
    name: "u-login___it"
  }, {
    path: "/u/manage",
    component: _f3c97b04,
    name: "u-manage___it"
  }, {
    path: "/u/membership",
    component: _d1ddb822,
    name: "u-membership___it"
  }, {
    path: "/u/register",
    component: _300ec87c,
    name: "u-register___it"
  }, {
    path: "/en/internal/events",
    component: _10cef0bc,
    name: "internal-events___en"
  }, {
    path: "/en/internal/home",
    component: _4b999842,
    name: "internal-home___en"
  }, {
    path: "/en/internal/list",
    component: _8e6d46fe,
    name: "internal-list___en"
  }, {
    path: "/en/internal/login",
    component: _457e5696,
    name: "internal-login___en"
  }, {
    path: "/en/internal/membership",
    component: _5001990e,
    name: "internal-membership___en"
  }, {
    path: "/en/internal/news",
    component: _4ab61a39,
    name: "internal-news___en"
  }, {
    path: "/en/internal/ticketcheck",
    component: _5384792e,
    name: "internal-ticketcheck___en"
  }, {
    path: "/en/internal/validate",
    component: _6dabab79,
    name: "internal-validate___en"
  }, {
    path: "/en/marketold/calendar",
    component: _a9048f3e,
    name: "marketold-calendar___en"
  }, {
    path: "/en/marketold/contribute",
    component: _6b4e7560,
    name: "marketold-contribute___en"
  }, {
    path: "/en/u/forgot-password",
    component: _44aa3e3c,
    name: "u-forgot-password___en"
  }, {
    path: "/en/u/login",
    component: _6aed1740,
    name: "u-login___en"
  }, {
    path: "/en/u/manage",
    component: _f3c97b04,
    name: "u-manage___en"
  }, {
    path: "/en/u/membership",
    component: _d1ddb822,
    name: "u-membership___en"
  }, {
    path: "/en/u/register",
    component: _300ec87c,
    name: "u-register___en"
  }, {
    path: "/u/confirm/activate",
    component: _1e24b8bb,
    name: "u-confirm-activate___it"
  }, {
    path: "/u/confirm/expired",
    component: _88bf72e6,
    name: "u-confirm-expired___it"
  }, {
    path: "/u/confirm/success",
    component: _758b406b,
    name: "u-confirm-success___it"
  }, {
    path: "/u/confirm/trigger",
    component: _cee518c0,
    name: "u-confirm-trigger___it"
  }, {
    path: "/u/recover/activate",
    component: _59455c97,
    name: "u-recover-activate___it"
  }, {
    path: "/u/recover/expired",
    component: _522db731,
    name: "u-recover-expired___it"
  }, {
    path: "/u/recover/success",
    component: _0c18b10f,
    name: "u-recover-success___it"
  }, {
    path: "/en/u/confirm/activate",
    component: _1e24b8bb,
    name: "u-confirm-activate___en"
  }, {
    path: "/en/u/confirm/expired",
    component: _88bf72e6,
    name: "u-confirm-expired___en"
  }, {
    path: "/en/u/confirm/success",
    component: _758b406b,
    name: "u-confirm-success___en"
  }, {
    path: "/en/u/confirm/trigger",
    component: _cee518c0,
    name: "u-confirm-trigger___en"
  }, {
    path: "/en/u/recover/activate",
    component: _59455c97,
    name: "u-recover-activate___en"
  }, {
    path: "/en/u/recover/expired",
    component: _522db731,
    name: "u-recover-expired___en"
  }, {
    path: "/en/u/recover/success",
    component: _0c18b10f,
    name: "u-recover-success___en"
  }, {
    path: "/",
    component: _201004a3,
    name: "index___it"
  }, {
    path: "/en/internal/event/:id?",
    component: _72ec2b72,
    name: "internal-event-id___en"
  }, {
    path: "/en/internal/news/:id",
    component: _6171523e,
    name: "internal-news-id___en"
  }, {
    path: "/en/internal/view/:id?",
    component: _f761d69a,
    name: "internal-view-id___en"
  }, {
    path: "/en/u/confirm/:confirmId?",
    component: _efe37478,
    name: "u-confirm-confirmId___en"
  }, {
    path: "/en/u/recover/:confirmId?",
    component: _7d9562a0,
    name: "u-recover-confirmId___en"
  }, {
    path: "/en/event/:id?",
    component: _6bdf6ad4,
    name: "event-id___en"
  }, {
    path: "/en/internal/:404",
    component: _08d53edc,
    name: "internal-404___en"
  }, {
    path: "/en/news/:id?",
    component: _8d5e7986,
    name: "news-id___en"
  }, {
    path: "/en/u/:404",
    component: _366a3fd2,
    name: "u-404___en"
  }, {
    path: "/internal/event/:id?",
    component: _72ec2b72,
    name: "internal-event-id___it"
  }, {
    path: "/internal/news/:id",
    component: _6171523e,
    name: "internal-news-id___it"
  }, {
    path: "/internal/view/:id?",
    component: _f761d69a,
    name: "internal-view-id___it"
  }, {
    path: "/u/confirm/:confirmId?",
    component: _efe37478,
    name: "u-confirm-confirmId___it"
  }, {
    path: "/u/recover/:confirmId?",
    component: _7d9562a0,
    name: "u-recover-confirmId___it"
  }, {
    path: "/en/:404",
    component: _15498d90,
    name: "404___en"
  }, {
    path: "/en/:oldindex",
    component: _8e78108a,
    name: "oldindex___en"
  }, {
    path: "/event/:id?",
    component: _6bdf6ad4,
    name: "event-id___it"
  }, {
    path: "/internal/:404",
    component: _08d53edc,
    name: "internal-404___it"
  }, {
    path: "/news/:id?",
    component: _8d5e7986,
    name: "news-id___it"
  }, {
    path: "/u/:404",
    component: _366a3fd2,
    name: "u-404___it"
  }, {
    path: "/:404",
    component: _15498d90,
    name: "404___it"
  }, {
    path: "/:oldindex",
    component: _8e78108a,
    name: "oldindex___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
