<template>
    <div class="main-flex">
        <div>
            <div
                v-if="pwaSnackbarActive || iosSnackbarActive"
                class="notification-container"
            >
                <div
                    class="pwa-backdrop"
                    v-if="pwaSnackbarActive || iosSnackbarActive"
                    :class="pwaSnackbarActive ? 'is-active' : ''"
                    @click="dismissPwa"
                ></div>
                <b-notification
                    class="ios-install"
                    :active.sync="pwaSnackbarActive"
                    :queue="false"
                    indefinite
                    :closable="false"
                    :class="pwaSnackbarActive ? 'is-active' : ''"
                >
                    <b-button
                        icon-pack="fas"
                        icon-right="fas fa-times-circle"
                        class="is-secondary dismiss-btn has-icon-right mr-2"
                        @click="dismissPwa"
                    />
                    <div class="notification-body">
                        <div class="icon-flex">
                            <img
                                class="app-icon"
                                :src="'/icons/public/ios/512.png'"
                            />
                        </div>
                        <div class="cta">
                            <h1
                                class="title is-5 mb-3 is-primary-alt has-text-weight-bold is-uppercase"
                            >
                                {{ $t("app.snackbar.title") }}
                            </h1>
                            <div class="install-body mb-3">
                                {{ $t("app.snackbar.description") }}
                            </div>
                            <div class="install-actions flex-row-end">
                                <b-button
                                    icon-pack="far"
                                    icon-right="far fa-plus-square"
                                    class="is-primary-alt has-icon-right"
                                    @click="installPwa"
                                >
                                    {{ $t("app.snackbar.action") }}
                                </b-button>
                            </div>
                        </div>
                    </div>
                </b-notification>
                <b-notification
                    class="ios-install"
                    :active.sync="iosSnackbarActive"
                    :queue="false"
                    indefinite
                    :closable="false"
                    :class="iosSnackbarActive ? 'is-active' : ''"
                >
                    <b-button
                        icon-pack="fas"
                        icon-right="fas fa-times-circle"
                        class="is-secondary dismiss-btn has-icon-right mr-2"
                        @click="dismissPwa"
                    />
                    <div class="notification-body">
                        <div class="icon-flex">
                            <img
                                class="app-icon"
                                src="~/static/icons/public/ios/512.png"
                            />
                        </div>
                        <div class="cta">
                            <h1
                                class="title is-5 mb-3 is-primary-alt has-text-weight-bold is-uppercase"
                            >
                                {{ $t("app.snackbar.title") }}
                            </h1>
                            <div>
                                {{ $t("app.snackbar.description") }}
                            </div>

                            <div>
                                {{ $t("app.snackbar.instructions1") }}
                                <img
                                    class="safari-icon"
                                    src="~/static/ios-pwa/share.png"
                                />
                                {{ $t("app.snackbar.instructions2") }}
                                <img
                                    class="safari-icon"
                                    src="~/static/ios-pwa/aths.png"
                                />
                                {{ $t("app.snackbar.instructions3") }}
                            </div>
                        </div>
                    </div>
                </b-notification>
            </div>
            <UserNavbar
                :isSolidNavbar="isHeaderSolid"
                :currentPage="activeRoute"
            />

            <nuxt />
        </div>
        <AppFooter :visible="!mobile" />
    </div>
    <!-- </div> -->
</template>
<style lang="scss" scoped>
.main-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // must flex through viewport height
    min-height: calc(100vh - 5rem);
    z-index: 0;
}
.footer {
    // position: fixed;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // width: 100%;
    // z-index: 100;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    // align-items: center;
    // pointer-events: none;
    // hover
    // opacity: 0.2;
    // &:hover {
    //     pointer-events: all;
    //     opacity: 1;
    // }
}
.main-content {
    // min-height: 75vh;
    opacity: 0;
    // min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // justify-content: center;
    // width: 100vw;
}

.pwa-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    // background-color: rgba(0, 0, 0, 0.5);
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    backdrop-filter: blur(0.5rem);
    // display: none;
    // &.is-active {
    //     display: flex;
    // }
}
.notification-container {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 100;
    pointer-events: none;
    // pointer-events: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    // pointer-events: none;

    // .media {
    // .media-content {
    //     overflow: visible !important;
    // }
    // }
}
.is-ios-blue {
    color: #3a70fb;
}

.dismiss-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 !important;
    border-radius: var(--radius-sm);
}
.notification-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-size: 1rem;
    // width: 100%;
    // overflow: hidden;
    padding: 1.5rem;
    pointer-events: auto;
}
.ios-install {
    pointer-events: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    // left: 0;
    margin: 0;
    padding: 0;

    z-index: 101;

    // width: 100%;
    min-width: 220px;
    max-width: 768px;
    margin: 0;
    border-radius: 0;
    border-top-left-radius: var(--radius-sm);
    border-top-right-radius: var(--radius-sm);
    box-shadow: 0 -8px 16px 0 rgba(var(--color-primary-alt-rgba), 0.2);
    // background-color: var(--bg);
    background-color: var(--color);
    // color: var(--color);
    color: var(--bg);
    // text-align: center;
    transition: all 0.3s ease-in-out;
    transform: translateY(100%);
    opacity: 0;
    pointer-events: none;
    &.is-active {
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
    }
}

.install-body {
    line-height: 1.1875rem;
}

.icon-flex {
    // width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    // min-height: max-content;
    // padding: 1rem;
    // margin-bottom: 1rem;
    // background-color: var(--color);
}

.flex-row-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    // margin-bottom: 1rem;
}

.safari-icon {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0;
    margin-bottom: -0.5rem;
    object-fit: contain;
}
.app-icon {
    // width: 10rem;
    aspect-ratio: 1/1;
    // position: absolute;
    width: 80%;

    height: 80%;
    min-width: 4rem;
    min-height: 4rem;
    max-width: 6rem;
    max-height: 6rem;
    margin-right: 1rem;
    // height: 100%;
    object-fit: contain;
    // margin-right: 1rem;
    // width: 4rem;
    // min-width: 4rem;
    // height: 4rem;
    // left: 0;
    // width: 60vw;
    // height: 60vw;
    object-fit: cover;
    border-radius: 1.125rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    // overflow: hidden;
}

.cta {
    max-width: 380px;
    // width: 75%;
}
</style>
<script>
// import CookieNotification from "~/components/CookieNotification.vue";
import AppFooter from "~/components/AppFooter.vue";
// import BackNavbar from "../components/BackNavbar.vue";
import UserNavbar from "../components/UserNavbar.vue";

import * as Cookies from "js-cookie";
export default {
    name: "DefaultLayout",
    components: { UserNavbar, AppFooter },
    // components: {
    //   navbar,
    // },
    transition: {
        appear: true,
        // css: false,
        // beforeEnter(el) {
        // 	console.log('PAGE - Before Entering')
        // },
        // enter(el, done) {
        // 	console.log('PAGE - Entering')
        // 	done()
        // },

        // leave(el, done) {
        // 	console.log('PAGE - Leaving')
        // 	done()
        // }
    },
    asyncData() {
        // const main = document.querySelector('.main-content')
        // console.warn(main.offsetTop)
        // window.addEventListener("scroll");
    },
    data() {
        return {
            mobile: false,
            loggedIn: this.$store.getters["getLoggedInStatus"],
            iosSnackbarActive: false,
            pwaSnackbarActive: false,
            activeRoute: "",
            isHeaderSolid: false,
            isFooterShown: false,
            beforeInstallPromptEvent: null,
        };
    },
    head() {
        return {
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            title: this.pageTitle,
            description:
                "Tempio Del Futuro *Perduto is a multifunctional cultural center, a place of sharing and solidarity, experimentation and artistic research.",
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content:
                        "Tempio Del Futuro *Perduto is a multifunctional cultural center, a place of sharing and solidarity, experimentation and artistic research.",
                },
                {
                    name: "viewport",
                    content:
                        "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no, minimal-ui",
                },
                {
                    name: "apple-mobile-web-app-capable",
                    content: "yes",
                },
            ],
            script: [
                {
                    // teermly embed
                    src: "https://app.termly.io/embed.min.js",
                    // async: true,
                    "data-website-uuid": "4cbe14a8-ce6c-4122-bd85-c25bae5ef93e",
                    "data-auto-block": "on",
                    type: "text/javascript",
                },
                {
                    src: "/pwa.js",
                },
            ],
            link: [
                {
                    rel: "manifest",
                    href: "/public/manifest.json",
                },
                {
                    href: "/splash/apple_splash_2048.png",
                    sizes: "2048x2732",
                    rel: "apple-touch-startup-image",
                },
                {
                    href: "/splash/apple_splash_1668.png",
                    sizes: "1668x2224",
                    rel: "apple-touch-startup-image",
                },
                {
                    href: "/splash/apple_splash_1536.png",
                    sizes: "1536x2048",
                    rel: "apple-touch-startup-image",
                },
                {
                    href: "/splash/apple_splash_1125.png",
                    sizes: "1125x2436",
                    rel: "apple-touch-startup-image",
                },
                {
                    href: "/splash/apple_splash_1242.png",
                    sizes: "1242x2208",
                    rel: "apple-touch-startup-image",
                },
                {
                    href: "/splash/apple_splash_750.png",
                    sizes: "750x1334",
                    rel: "apple-touch-startup-image",
                },
                {
                    href: "/splash/apple_splash_640.png",
                    sizes: "640x1136",
                    rel: "apple-touch-startup-image",
                },
            ],
        };
    },
    beforeMount() {
        // setInterval(this.checkUser, 10000);
    },

    // on route change, check if user is logged in

    // as soon as the component is mounted, we check if the user is logged in

    beforeDestroy() {
        // window.removeEventListener("scroll");
        // this.$colorMode.preference = "light";
        // this.$colorMode.forced = false;
    },
    // fetch() {
    //     // this.checkUser();

    // },
    mounted() {
        this.mobile = this.$store.getters["getIsMobile"];
        this.$nextTick(function () {
            this.mobile = this.$store.getters["getIsMobile"];
        });
        const user = this.checkUser();
        const isDev = process.env.NODE_ENV !== "production";
        this.loggedIn = this.$store.getters["getLoggedInStatus"];

        if (user && user.refToken) {
            // console.log("user", user);
            this.$store.dispatch("setLoggedIn", true);
        } else {
            // remove secure cookie on client
            if (this.$cookies.get("authrefToken").length > 0) {
                Cookies.remove("authrefToken", {
                    path: "/",
                    domain: isDev ? "localhost" : process.env.BROWSER_BASE_URL,
                    secure: !isDev,
                });
            }
        }

        let beforeInstallPrompt = null;
        const isIos = () => {
            return (
                [
                    "iPad Simulator",
                    "iPhone Simulator",
                    "iPod Simulator",
                    "iPad",
                    "iPhone",
                    "iPod",
                ].includes(navigator.platform) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") &&
                    "ontouchend" in document)
            );
        };
        const isInStandaloneMode = () =>
            "standalone" in window.navigator && window.navigator.standalone;

        if (isIos() && !isInStandaloneMode()) {
            this.iosSnackbarActive = true;
        }

        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            // beforeInstallPrompt is not defined
            beforeInstallPrompt = event;
            if (beforeInstallPrompt) {
                this.beforeInstallPromptEvent = event;

                event.preventDefault();

                this.pwaSnackbarActive = true;
            }
        });

        window.addEventListener("appinstalled", (event) => {
            console.log("App installed");
        });

        window.addEventListener("load", () => {
            beforeInstallPrompt = window.beforeInstallPrompt;
            if (beforeInstallPrompt) {
                this.beforeInstallPromptEvent = beforeInstallPrompt;
                event.preventDefault();
                this.pwaSnackbarActive = true;
                // beforeInstallPrompt.prompt();
                // this.$buefy.snackbar.open(installSnackbar);
            }
        });

        this.activeRoute = this.$router.currentRoute.name;
        // console.log(this.$store.getters["getCurrPageTitle"]);
        if (this.activeRoute === "virtuale") {
            this.isFooterShown = false;
        } else {
            this.isFooterShown = true;
        }
        if (
            // this.activeRoute === "newhome" ||
            this.activeRoute === "radio" ||
            this.activeRoute === "where" ||
            this.activeRoute === "virtuale"
        ) {
            this.isHeaderSolid = false;
        } else {
            this.isHeaderSolid = true;
        }
    },
    // next tick
    // updated() {
    //     // console.log("updated");
    //     this.$nextTick(() => {
    //         const user = this.checkUser();
    //         // this.loggedIn = false;
    //         // console.log("user", user);
    //         if (user) {
    //             console.log("user logged in", user);
    //             this.$store.dispatch("setLoggedIn", true);
    //         } else {
    //             console.log("user not logged in", user);
    //             this.$store.dispatch("setLoggedIn", false);
    //         }
    //     });
    // },
    watch: {
        $route(to, from) {
            // console.log("route changed", to, from);
            const user = this.checkUser();
            // this.loggedIn = false;
            // console.log("user", user);
            if (user && user.id) {
                // console.log("user logged in", user);
                this.$store.dispatch("setLoggedIn", true);
            } else {
                // console.log("user not logged in", user);
                this.$store.dispatch("setLoggedIn", false);
            }
            this.activeRoute = this.$router.currentRoute.name;
            // const route = this.$router.currentRoute.path;
            if (this.activeRoute === "virtuale") {
                this.isFooterShown = false;
            } else {
                this.isFooterShown = true;
            }
            if (
                // this.activeRoute === "newhome" ||
                this.activeRoute === "live" ||
                this.activeRoute === "where" ||
                this.activeRoute === "virtuale"
            ) {
                this.isHeaderSolid = false;
            } else {
                this.isHeaderSolid = true;
            }
            this.loggedIn = this.$store.getters["getLoggedInStatus"];
        },
    },
    methods: {
        checkUser() {
            const user = this.$store.getters["public-auth/authedUser"];
            const isDev = process.env.NODE_ENV !== "production";
            this.loggedIn = this.$store.getters["getLoggedInStatus"];

            if (
                user &&
                user.refToken &&
                this.$cookies.get("authrefToken").length > 0
            ) {
                const tokenInCookie = JSON.parse(
                    this.$cookies.get("authrefToken")
                );

                if (tokenInCookie.auth.refToken !== user.refToken) {
                    if (this.$cookies.get("authrefToken").length > 0) {
                        Cookies.remove("authrefToken", {
                            path: "/",
                            domain: isDev
                                ? "localhost"
                                : process.env.BROWSER_BASE_URL,
                            secure: !isDev,
                        });
                        this.$store.dispatch("setLoggedIn", false);
                    }
                } else {
                    this.$store.dispatch("setLoggedIn", true);
                }
            } else {
                this.$store.dispatch("setLoggedIn", false);

                const isDev = process.env.NODE_ENV !== "production";

                if (this.$cookies.get("authrefToken").length > 0) {
                    Cookies.remove("authrefToken", {
                        path: "/",
                        domain: isDev
                            ? "localhost"
                            : process.env.BROWSER_BASE_URL,
                        secure: !isDev,
                    });
                }
            }
            return user;
        },
        dismissPwa() {
            this.pwaSnackbarActive = false;
            this.iosSnackbarActive = false;
        },
        installPwa() {
            if (this.beforeInstallPromptEvent) {
                this.pwaSnackbarActive = false;
                event.preventDefault();
                this.beforeInstallPromptEvent.prompt();
                this.beforeInstallPromptEvent.userChoice.then(
                    (choiceResult) => {
                        if (choiceResult.outcome === "accepted") {
                            console.log("User accepted the A2HS prompt");
                        } else {
                            console.log("User dismissed the A2HS prompt");
                        }
                        this.beforeInstallPromptEvent = null;
                    }
                );
            }
        },
    },
};
</script>
